import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo/Seo';
import CategoryBoxContainer from '@/containers/CategoriesProduct/CategoryBoxContainer';

import { PrimaryCategoryQuery } from '../../graphql-types';

interface Props {
  data: PrimaryCategoryQuery;
  pageContext: '';
}

export const query = graphql`
  query SecondaryCategoryBox($id: String!) {
    prismicPrimaryCategory(id: { eq: $id }) {
      id
      data {
        name {
          text
        }
        secondary_categories {
          secondary_category {
            document {
              ... on PrismicSecondaryCategory {
                id
                uid
                data {
                  name {
                    text
                  }
                  image {
                    url
                    alt
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SecondaryCategoryBoxTemplate = ({ data }: Props): JSX.Element => {
  const CatName = data?.prismicPrimaryCategory?.data?.name?.text;

  return (
    <Layout invertNav type="magasin">
      <Seo
        title={`Catégorie ${CatName}`}
        description={`Catégories secondaires de ${CatName}`}
      />
      <CategoryBoxContainer data={data} />
    </Layout>
  );
};

export default SecondaryCategoryBoxTemplate;
